<template>
  <div class="home pb50">
    <keep-alive :include="keepAliveList">
      <router-view />
    </keep-alive>
    <van-tabbar v-model="active">
      <van-tabbar-item
        :icon="`iconfont iconfont ${
          active == 'index' ? 'iconshouye-xuanzhong' : 'iconshouye-weixuan'
        }`"
        name="index"
        to="/index"
        v-if="userType == 1"
        >首页</van-tabbar-item
      >
      <van-tabbar-item
        name="event"
        :icon="`iconfont iconfont ${
          active == 'event' ? 'iconshijian-xuanzhong' : 'iconshijian-weixuan'
        }`"
        to="/event"
        >事件</van-tabbar-item
      >
      <van-tabbar-item
        name="enterprise"
        :icon="`iconfont iconfont ${
          active == 'enterprise' ? 'iconqiye-xuanzhong' : 'iconqiyerenzheng'
        }`"
        to="/enterprise"
        >企业</van-tabbar-item
      >
      <van-tabbar-item
        name="setting"
        :icon="`iconfont iconfont ${
          active == 'setting' ? 'iconjilu-xuanzhong' : 'iconjiluweixuan'
        }`"
        to="/Setting"
        v-if="userType == 2"
        >电子档案</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  watch: {
    "$route.name": {
      handler: "routeChange",
      immediate: true,
    },
  },
  computed: {
    userType() {
      // 1 环保局用户 2企业用户 3运维用户
      return localStorage.getItem("userType");
    },
    keepAliveList() {
      return this.$store.getters.keepAliveList;
    },
  },
  methods: {
    // 路由改变事件
    routeChange() {
      switch (this.$route.name) {
        case "Index":
          this.active = "index";
          break;
        case "Event":
          this.active = "event";
          break;
        case "Enterprise":
          this.active = "enterprise";
          break;
        case "Operation":
          this.active = "operation";
          break;
        case "Setting":
          this.active = "setting";
          break;
        default:
          this.active = "index";
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
