var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home pb50"},[_c('keep-alive',{attrs:{"include":_vm.keepAliveList}},[_c('router-view')],1),_c('van-tabbar',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[(_vm.userType == 1)?_c('van-tabbar-item',{attrs:{"icon":`iconfont iconfont ${
        _vm.active == 'index' ? 'iconshouye-xuanzhong' : 'iconshouye-weixuan'
      }`,"name":"index","to":"/index"}},[_vm._v("首页")]):_vm._e(),_c('van-tabbar-item',{attrs:{"name":"event","icon":`iconfont iconfont ${
        _vm.active == 'event' ? 'iconshijian-xuanzhong' : 'iconshijian-weixuan'
      }`,"to":"/event"}},[_vm._v("事件")]),_c('van-tabbar-item',{attrs:{"name":"enterprise","icon":`iconfont iconfont ${
        _vm.active == 'enterprise' ? 'iconqiye-xuanzhong' : 'iconqiyerenzheng'
      }`,"to":"/enterprise"}},[_vm._v("企业")]),(_vm.userType == 2)?_c('van-tabbar-item',{attrs:{"name":"setting","icon":`iconfont iconfont ${
        _vm.active == 'setting' ? 'iconjilu-xuanzhong' : 'iconjiluweixuan'
      }`,"to":"/Setting"}},[_vm._v("电子档案")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }